<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>
        <div class="box-table">
            <v-data-table
                :headers="headers"
                :items="votes"
                :page.sync="page"
                sort-by="name"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pageCount = $event">
                <template v-slot:[`item.avatar`]="{}">
                    <v-avatar color="#dddddd" size="40"></v-avatar>
                </template>
                <template v-slot:[`item.voted`]="{ item }">
                    <v-icon :color="getIconVoted(item.voted).color">
                        {{ getIconVoted(item.voted).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="remove(item)" icon title="Adicionar observacao">
                        <v-icon>mdi-message-processing-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import monitoreCampaignMock from '@/modules/manager/mocks/monitore.campaign.mock';

    export default {
        name: 'MonitoreCampaign',
        data() {
            return {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                titles: {
                    sector: 'Monitoramento'
                },
                headers: [
                    {
                        text: 'Foto',
                        align: 'center',
                        sortable: false,
                        value: 'avatar'
                    },
                    { text: 'Nome', value: 'name' },
                    { text: 'Votou?', value: 'voted', align: 'center' },
                    { text: 'Ações', value: 'actions', align: 'center', sortable: false }
                ],
                votes: [...monitoreCampaignMock.votes]
            };
        },
        computed: {
            getIconVoted() {
                return (activated) => {
                    return {
                        icon: 'mdi-check-circle',
                        color: activated ? 'green' : '#d0d0d0'
                    };
                };
            }
        }
    };
</script>

<style scoped>
    .sector-head {
        margin-bottom: 20px;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
